.form_group_modal {
   overflow: hidden;
}

/* Style the tab content */
.form_group_modal {
   display: none;
}
.form_group_modal_footer {
   display: none;
}
.form_group_modal {
   animation: fadeEffect 1s; /* Fading effect takes 1 second */
}

/* Go from zero to full opacity */
@keyframes fadeEffect {
   from {opacity: 0;}
   to {opacity: 1;}
}