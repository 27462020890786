//@import "fonts"; // load fonts first
// bootstrap
@import "variables"; // override bootstrap variables;
@import "~bootstrap/scss/bootstrap";



// fontAwesome
@import "~@fortawesome/fontawesome-free/css/all.css";

// magnificPopup
@import "~magnific-popup/src/css/main";
@import "~magnific-popup/src/css/settings";

// cookie consent
//@import "~cookieconsent/src/styles/animation.css";
//@import "~cookieconsent/src/styles/base.css";
//@import "~cookieconsent/src/styles/layout.css";
//@import "~cookieconsent/src/styles/media.css";
//@import "~cookieconsent/src/styles/themes/classic.css";s

// select2
@import "~select2/src/scss/core";
@import "~select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.rtl.min.css";

//Slick
@import "~slick-carousel/slick/slick";

// daterangepicker
@import "~bootstrap-daterangepicker/daterangepicker.css";



@import "navbar";
@import "home-page";
@import "login";
@import "register";
@import "add-event";
@import "profile-menu";
@import "general-card";
@import "kapo-forms";
@import "profile";
@import "search";
@import "detail";
@import "icons";
@import "pagination";
@import "modal";


//select2 fix height
.select2-container .select2-selection--single {
	//height: calc(2.25rem + 2px);
	//height: calc(1.5em + 1.5rem + 2px);
	height: calc(1.5em + 0.75rem + 2px);


  .select2-selection__placeholder {
    color: black;
  }
}

.filter-item .select2-container .select2-selection--single {
  border: none;
}


.btn-25 {
	padding-left: 25px;
	padding-right: 25px;
}

.btn-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.floating-button {
	position: fixed;
	z-index: 100;
	bottom: 10px;
	left: 10px;

	button {
		border-radius: 50px;
	}
}

/* width */
::-webkit-scrollbar {
  width: 14px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-hover;
}

.ui-tooltip {
  display: none !important;
}