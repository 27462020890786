@import "colors";

.profile-side-menu-item {
  position: relative;
  background-color: $yellow;
  padding: 20px 30px 20px 0;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 20px;
  list-style-position: inside;
  list-style-type: none;
  font-weight: bold;

  .profile-side-menu-item-title {

    a {
      line-height: 20px;
      color: black;
      padding-left: 15px;
      font-size: 15px;
    }

    padding-bottom: 15px;
  }

  ul {

    list-style-position: inside;
    list-style-type: none;

    li {
      padding-bottom: 5px;
      font-size: 14px;

      a {
        line-height: 14px;
        color: black;
      }

      &:hover {
        a {
          color: $light-gray;
        }
      }
    }
  }
}

.tab-link {
  cursor: pointer;
}