@import "colors";

.navbar_logo {
  width: 155px;
}
#navbar {
  flex-wrap: wrap;
  gap: 12px
}
.kapo-navbar {
  padding-bottom: 22px;
  padding-top: 22px;
  gap: 12px;
}

.kapo-navbar__logo {
  width: 60px;
  height: 60px;
  background-color: $yellow;
  border-radius: 50px;
  margin-right: 8px;

  i {
    font-size: 30px;
    color: #fff;
  }
}

.kapo-navba__title {
  font-size: 36px;
  font-weight: 600;
  color: black;
  line-height: 1;
  letter-spacing: 4px;

  &:hover {
    text-decoration: none;
    color: #000;
  }
}

.kapo-navbar__title--small {
  font-size: 12px;
  font-weight: 300;
  color: rgba(#000, .8);
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .kapo-navbar__search {
    margin-left: 50px;
  }

  .kapo-navbar__actions {
    flex-direction: row !important;
  }

}

.kapo-navbar__search {
  i {
    color: #fff;
  }
}

.nav-item {
  flex-grow: 1;
}

.nav-link {
  text-wrap: nowrap;
  color: black;
  font-family: Arial, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
}

.navbar-toggler {
  border: 0;
  padding: 0;

}

.navbar-toggler-icon {
  background-image: url('../img/toggler-icon.svg');
  width: 20px;
  height: 18px;
}

.nav-link {
  margin-right: 10px;
}


.search-wrapper .select2-container .select2-selection--single
{
  background-image: url("../img/icon-search.svg") !important;

}

.search-wrapper {
  flex-grow: 1;

  .input-group .select2-container--bootstrap-5 .select2-selection {
    height: calc(1.5em + 0.75rem + 2px);
    width: fit-content;
    min-width: 400px;
    margin: auto;
  }
  .select2-selection__arrow {
    margin-top: 10px;
    margin-right: 15px;

    b {
      background-image: url("../img/icon-search.svg") !important;
      background-color: transparent;
      background-size: contain;
      border: none !important;
      height: 20px !important;
      width: 20px !important;
      margin: auto !important;
      top: auto !important;
      left: auto !important;
    }
  }
}

.top-search {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .top-search {
    justify-content: center;
    margin-top: 0;
  }
}
