.profile-container {

  .title {
    font-family: 'Arial', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 39px;
  }

  padding-top: 60px;
  background-color: #f3f3f3;

  button, .link-button {
    color: white;
    min-width: 150px;
    min-height: 36px;
    font-family: Arial, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }
  .btn-lg {
    font-size: 18px;
    line-height: 25px;
  }

  .link-button {
    line-height: 36px;
    padding: 0;
  }

  .btn-group {
    button, .link-button {
      min-width: initial;
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  form {

    .select2-selection {
      border: 1px solid #aaa;
    }

    input {
      background-color: white;
    }

    select {
      background-color: white;
    }

    textarea {
      background-color: white;
      min-height: 150px;
    }
  }

  table {
    tr {
      td {
        color: black;
      }
    }
  }

  .panel-title {
    color: $primary;
    cursor: pointer;
  }

  .filter-categories span:not(:last-child)::after {

    content: ",";
  }

  .feed-row {
    font-size: 14px;
    white-space: nowrap;
  }
  .filter-table {
    font-size: 14px;
  }
  .form-group {
    label:not(.form-check-label) {
      margin-top: 10px;
    }
    label.required {
      font-weight: 700;
    }
  }
}