$rem-base: 16px !default;
$grid-gutter-width: 50px !default;
// functions
@function strip-unit($num) {
    @return $num / ($num * 0 + 1);
}

@function -zf-to-rem($value, $base: $rem-base) {
    // Calculate rem if units for $value is not rem
    @if (unit($value) != 'rem') {
      $value: strip-unit($value) / strip-unit($base) * 1rem;
    }
    // Turn 0rem into 0
    @if ($value == 0rem) {
      $value: 0;
    }
    @return $value;
}

@function rem-calc($values, $base: $rem-base) {
    $rem-values: ();
    $count: length($values);

    @if $base == null {
      $base: $rem-base;
    }

    @if $count == 1 {
      @return -zf-to-rem($values, $base);
    }

    @for $i from 1 through $count {
      $rem-values: append($rem-values, -zf-to-rem(nth($values, $i), $base));
    }

    @return $rem-values;
}
// COLORS
$black:                     #333333;
$dark-gray:                 #25282a;
$white:                     #ffffff;
$cool-grey:                 #f5f5f5;
$grey:                      #adb0b3;
$grey-darken:               #979797;
$gray:                      #dddddd;
$gray-darken:               #aaaaaa;
$darken:                    #32353b;
$error:                     #d93e36;
$raspberry:                 #d60037;
$silver:                    #dfe3e6;

/** ---------------------------- bootstrap overrides ---------------------------- **/
$primary:       #3791ec;
$secondary:     #59A6BC;
$success:       #81bf43;
$info:          $grey;
$warning:       #ffd24c;
$danger:        $error;
$light:         $silver;
$dark:          $black;
$white-darken:  #f2f3f5;
$primary-hover:       #48a2fd;

$theme-colors: ();
$theme-colors: map-merge(
    (
        "primary": $primary,
        "secondary":  $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark,
        "white": $white
    ),
    $theme-colors
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1200px,
);

$grid-breakpoints: (
    xxs: 0,
    xs: 480,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
);

.container {
  --bs-gutter-x: 40px !important;
}

$grid-gutter-width: 40px;
$grid-gutter-width-sm: 40px;
$grid-gutter-width-lg: 40px;

/** ----------------------------------- Theme ------------------------------------ **/

$color-normal:              $dark;
$color-normal-hover:        $black;
$color-normal-active:       $black;
$color-accent:              $primary;
$color-accent-hover:        #ff0040;
$color-accent-active:       #b90030;
$color-silent:              $info;
$color-silent-hover:        $black;
$color-silent-active:       $black;
$color-inverted:            $white;
$color-inverted-hover:      #c2c2c2;
$color-inverted-active:     #dbdbdb;
$color-footer:              rgba($white, 0.5);
$color-footer-hover:        $white;
$color-footer-active:       $white;
$dark-element-background:   rgba(50, 53, 59, 0.9);
$color-mail:                #494949;
$color-messenger:           #506aa2;
$color-facebook:            $color-messenger;
$color-pinterest:           #da204c;


$label-red: $raspberry;
$label-lilac: #8747c7;
$label-green: $success;

// Text colors
$body-color: $black;
$headings-color: $black;

$hover-colors: (
        $color-normal:      $color-normal-hover,
        $color-accent:      $color-accent-hover,
        $color-silent:      $color-silent-hover,
        $color-inverted:    $color-inverted-hover,
        $color-footer:      $color-footer-hover,
);

$active-colors: (
        $color-normal:      $color-normal-active,
        $color-accent:      $color-accent-active,
        $color-silent:      $color-silent-active,
        $color-inverted:    $color-inverted-active,
        $color-footer:      $color-footer-active,
);
// footer
$footer-color-background:       #33363b;
$footer-color-text:             $grey;
$footer-copy-color-background:  #2c2e33;
$footer-list-border-color:      #4c5059;
// inputs
$input-border-color-normal:     #c6c8cc;
$input-border-color-hover:      $darken;
$input-border-color-active:     $darken;
$input-border-color-error:      $error;
$input-border-color-checkbox:   $primary;

$form-placeholder-color:        $grey;
$form-text-color:               $darken;

$body-bg:                       $cool-grey;
$breadcrumb-bg:                 inherit;
$jumbotron-bg:                  $cool-grey;

// IMAGES
$hero-image-homepage: url('/build/images/hp-hero.png');
$hero-image-foreign: url('/build/images/hero_foreign.png');
$map-image-background: url('/build/images/map_image_big.png');
$img-placeholder: url('/build/images/img-placeholder.svg');
$broker-placeholder: url('/build/images/svg/broker_empty.svg');
$background-image-svk: url('/build/images/svg/slovakia.svg');
$background-image-earth: url('/build/images/svg/earth.svg');
$background-image-check: url('/build/images/svg/check.svg');
$background-image-arrow: url('/build/images/arrow.svg');
$background-image-time: url('/build/images/svg/time.svg');
$background-badge: url('/build/images/badge.svg');
$background-logo-white: url('/build/images/logo-white.png');

// FONTS
$font-name: 'Asap';
$font-family-sans-serif: $font-name, 'Helvetica Neue', sans-serif;

$font-size-doc-lg:  16px;
$font-size-doc-md:  16px;
$font-size-doc-sm:  14px;
$font-size-base:    1rem;
                                                             //  lg      |  md   |  sm   |
                                                             //---------------------------
$font-size-hero:        rem-calc(42px);                      // ~42px    | ~36px | ~34px |
$font-size-h1:          rem-calc(32px);                      // ~32px    | ~28px | ~26px |
$font-size-h2:          rem-calc(24px);                      // ~24px    | ~21px | ~19px |
$font-size-h3:          rem-calc(22px);                      // ~22px    | ~19px | ~18px |
$font-size-h4:          rem-calc(18px);                      // ~18px    | ~16px | ~15px |
$font-size-body:        $font-size-base;                     // ~16px    | ~14px | ~13px |
$font-size-normal:      14px;                               // ~14px    | ~12px | ~11px |
$font-size-small:       rem-calc(12px);                      // ~12px    | ~11px | ~10px |
$font-size-tiny:        rem-calc(10px);                      // ~10px    |  ~9px |  ~8px |
$font-size-20:          rem-calc(20px);
                                                             //---------------------------
                                                             //---------------------------
                                                             //  lg      |  md   |  sm   |
                                                             //---------------------------
$line-height-hero:      rem-calc(50px);                      // ~50px    | ~44px | ~41px |
$line-height-h1:        rem-calc(38px);                      // ~38px    | ~33px | ~31px |
$line-height-h2:        rem-calc(28px);                      // ~28px    | ~25px | ~23px |
$line-height-h3:        rem-calc(26px);                      // ~26px    | ~23px | ~21px |
$line-height-h4:        rem-calc(22px);                      // ~22px    | ~19px | ~18px |
$line-height-body:      rem-calc(24px);                      // ~24px    | ~21px | ~19px |
$line-height-small:     rem-calc(18px);                      // ~18px    | ~16px | ~15px |
$line-height-tiny:      rem-calc(14px);                      // ~14px    | ~12px | ~11px |
$line-height: 1.43;
$line-height-sm: 1.22;
$line-height-xs: 1.18;

$h1-font-size:          $font-size-h1;
$h2-font-size:          $font-size-h2;
$h3-font-size:          $font-size-h3;
$h4-font-size:          $font-size-h4;

// fixed font sizes
$font-fx-small: rem-calc(12px, $base: 16);

// Other
$border-radius-default:  4px;
$border-radius-default-left: 4px 0 0 4px;
$border-radius-default-right: 0 4px 4px 0;
$border-radius-default-top: 4px 4px 0 0;
$border-radius-default-bottom: 0 0 4px 4px;

$card-border-width: 0px;
$card-border-radius: 0px;
$card-inner-border-radius: 0px;
$card-spacer-x: 1rem;

$modal-content-border-width: 0;
$modal-content-border-radius: 0;

$input-height: calc(1.5em + 0.75rem + 2px);
$input-box-shadow: none;
$input-placeholder-color: $info;
$input-focus-width: 0;

$link-decoration: none;
$link-hover-decoration: none;

$custom-control-indicator-size: 0.75rem;
$custom-control-indicator-bg: transparent;
$custom-checkbox-indicator-border-radius: 3px;

$input-btn-padding-y: .75rem;
$input-btn-padding-x: .375rem;
$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$btn-padding-x: $input-btn-padding-y;
$btn-padding-y: $input-btn-padding-x;
$btn-padding-y-sm: rem-calc(8.5px);

$box-shadow-sm: 0 0 rem-calc(3px) 0 rgba(0, 0, 0, 0.2);
$input-border-color: #aaa;
$input-height-sm: rem-calc(42px);

$border-light: 1px solid $silver;
$border-grey: 1px solid $grey;
$border-grey-darken: 1px solid $grey-darken;
$border-white: 1px solid $white;
$border-white-darken: 1px solid $white-darken;
$border-cool-grey: 1px solid $cool-grey;
$border-input: 1px solid $input-border-color;
$border-input-hover: 1px solid $input-border-color-hover;
$border-transparent: 1px solid transparent;
$border-info: 1px solid $info;
$border-error: 1px solid $error;
$border-paginator: 1px solid #dfe2e6;

$border-style-vertical: none solid;
$border-style-bottom: none none none solid;

// sizes
$size-2: .125rem; // 2px
$size-4: .25rem; // 4px
$size-3: .1875rem; // 3px
$size-5: .3125rem; // 5px
$size-10: .625rem;

// filter
$filter_header_height: rem-calc(60px);

$default-box-shadow: 0 0.3rem 1.25rem 0 rgba(0, 0, 0, 0.05);
$expanded-box-shadow: 0 1px 0.5rem 1px rgba(212,212,213,0.71), 0 0 0 0 #838383;

$form-file-button-bg: $primary;
$form-file-button-color: $white;