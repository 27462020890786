.pagination .page-item {

  &.active a {
    background-color: $yellow;
    border: none;
    color: white;
  }

  a {
    color: black;
  }
}