@import "colors";

.kapo-login-form {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.login-form__btn-login {
  margin-top: 20px;
  height: 36px;
  border-radius: 5px;
  font-family: Arial, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.4px;
  color: #FFFFFF;

  @media (min-width: 768px) {
    height: 50px;
  }
}

.login-form__actions {
  margin-top: 10px;
  color: $blue-2;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: underline;
  }
}

.login-title {
  margin-bottom: 20px;
  font-weight: 700;
  font-family: Arial, serif;
}

.login-wrapper {
  .login-title {
    color: #FFF;
  }
  min-height: 80vh;
  padding-bottom: 50px;
  height: fit-content;
  background: url("../img/login-background.jpg") fixed;
  background-position-y: center;
  background-position-x: center;
}
.white-hr {
  border-top: 2px solid white;
  opacity:1;
  background: transparent;
}

.login-container {

  margin-top: 50px;
  background-color: $yellow;
  border-radius: 5px;
  padding: 20px 10px 10px;

  .oauth-buttons {
    h4 {
      color: white;
    }
  }

  .login-form__actions {

    a {
      color: white;
    }
  }

}

.kapo-login-form {

  .fa-google {
    color: red;
  }

  .fa-facebook-square {
    color: blue;
  }

  input {
    margin-top: 10px;
    border: none;
  }

  input::placeholder {
    color: black;
  }
}

.oauth-buttons button {
  width: 100%;
  margin-bottom: 10px;
}

