@import "colors";

.kapo-general-item-card {
  margin: auto;
  background-color: #fff;
  border-radius: 8px;
  max-width: 250px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.15);
  height: 100%;
  overflow: hidden;

  &:hover {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.45);
    transform: scale(1.01)
  }

  .card-image {
    height: 220px;
    position: relative;
    display: flex;
    align-items: self-end;
    justify-content: right;

    span {
      padding-right: 10px;

      i {
        cursor: pointer;
        filter:drop-shadow(0 0 7px white)
      }
    }

    img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 220px;
      top: 0;
      object-position: center;
    }

    .card-image-button {
      font-size: 20px;
      margin-right: 10px;
      margin-bottom: 5px;
      line-height: 20px;
      position: relative;
      background: transparent;
      border: none;

      &:hover {
        transform: scale(1.1);
      }

      &:focus {
        outline: none;
      }
    }
  }

  .card-info {
    padding-left: 25px;
    padding-bottom: 10px;
  }

  .card-categories {
    color: $gray-1;
    font-weight: 900;
    font-size: 13px;
    height: 95px;
    overflow: hidden;

    span {
      margin-right: 10px;
    }
  }

  .card-name {
    margin-top: 10px;
    color: #000;
    font-size: 16px;
    font-weight: 700;
  }

  .card-description {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 400;
    color: $black-60;
    height: 61px;
    overflow: hidden;
  }

  .card-date {
    margin-top: 10px;
    font-size: 13px;
    color: #000;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    i {
      color: $black-25;
    }
  }
}

#interpreter_performances {

}
