$likeRed: #FF3939;
$blue: #3791ec;
$blue-2: #1969bf;
$blue-3: #489ffa;
$light-blue: #5badff;
$light-2-blue: #3a5e83;
$yellow: #FEA72A;
$gray-1: #bfbfbf;
$gray-2: #dadada;
$light-gray: #f3f3f3;
$light-gray-2: #a7a7a7;
$black-80: rgba(#000, .8);
$black-75: rgba(#000, .75);
$black-70: rgba(#000, .7);
$black-60: rgba(#000, .6);
$black-50: rgba(#000, .5);
$black-40: rgba(#000, .4);
$black-25: rgba(#000, .25);
$white-50: rgba(#fff, .5);
$white-60: rgba(#fff, .6);
$white-10: rgba(#fff, .1);
