@import "colors";

.kapo-detail-card {
  padding-top: 40px;
}

.kapo-detail-headline {
  font-size: 25px;
  font-weight: 700;
}

.kapo-detail-image {
  img {
    max-height: 400px;
    max-width: 100%;
  }
}

.kapo-detail-social {
  span:nth-child(1) {
    vertical-align: super;
    margin-bottom: 20px;
  }

  span {
    vertical-align: middle;
    height: 35px;
    line-height: 35px;
    display: inline-block;
    width: 170px;
  }
}

@media (max-width: 767px) {
  .kapo-detail-social span {
    display: block;
  }
  .kapo-detail-social span:nth-child(1) {
    margin-bottom: 0;
  }
}

button.kapo-detail-social-item {
  background-color: transparent;
  color: $blue;
  margin-right: 10px;
  border: 1px solid $blue;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: inline-block;
  min-width: initial;
  min-height: initial;
  font-size: initial;
  font-weight: initial;
  line-height: initial;
}

button.kapo-detail-like {
  color: $likeRed;
  background-color: transparent;
  outline: none;
  padding: 0;
  border: none;
  min-width: initial;
  min-height: initial;
  font-size: initial;
  font-weight: initial;
  line-height: initial;

  i {
    font-size: 30px;
  }
}

.kapo-detail-description-headline {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 30px;
}

.kapo-detail-description {
  overflow-wrap: break-word;
  font-size: 16px;
  line-height: 22px;
}

.kapo-detail-icon {
  color: black;
  width: 20px;
  margin-right: 10px;
}

.kapo-detail-info-data {
  font-family: 'Arial', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #313131;
}

.kapo-detail-venue {
  display: flex;
  flex-direction: column;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.kapo-detail__venue-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal !important;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.kapo-detail__tags {

}

.kapo-detail__tag {
  margin-right: 5px;
  padding: 5px 10px;

  color: $blue;

  border: 1px solid #CFCFCF;
  border-radius: 5px;
  white-space: nowrap;
  font-family: 'Arial', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 30px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.4px;
}

.kapo-detail__tag:hover {
  text-decoration: underline;
}

.kapo-detail-organizer {
  font-size: 14px;
  color: $black-75;
  font-weight: 300;
}

.kapo-detail-preview {
  img {
    border-radius: 5px;
    max-width: 200px;
    aspect-ratio: 2/1.5;
    object-fit: cover;
  }

  margin-bottom: 10px;
}

.kapo-detail-info-headline {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}


.kapo-detail-images-slide-show {
  margin-left: 20px;
  margin-right: 20px;
  position: relative;

  .slide-show-swipe {
    position: absolute;
    top: 55%;
    color: $black-25;
    font-size: 26px;
    cursor: pointer;
    transform: translateY(-50px);

    &.swipe-disabled {
      color: $black-25;
    }

    &:hover {
      color: $black-60;
    }
  }

  .slide-show-swipe--left {
    left: -30px;
  }

  .slide-show-swipe--right {
    right: -30px;
  }

  .slide-show-images {
    overflow: hidden;
  }

  .slick-list {
    padding: 0;
  }

  .slick-dots {
    list-style-type: none;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-bottom: 0;
    cursor: pointer;
  }

  .slick-slide {
    margin-right: 20px;
    margin-left: 20px;
  }

  .slick-dots .slick-active span {
    background-color: $black-60;
  }

  .slide-show-dots {
  }

  .slide-show-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin-right: 5px;
    background-color: $black-25;

    &.active {
      background-color: $black-60;
    }
  }

}

.kapo-detail-galery-image {
  width: 100%;
  object-fit: cover;
  display: block;
  aspect-ratio: 2 / 1
}

.kapo-detail-info-margin {
  margin-top: 40px;
}

.kapo-detail {
  background-color: #F3F3F3;
}

.kapo-detail-header {
  background-color: white;
}

@media (max-width: 767px) {
  .kapo-detail-header {
    margin-top: 0;
  }
}

.kapo-event-name {
  font-family: 'Arial', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 34px;
  padding-top: 50px;
  padding-bottom: 25px;
}

.kapo-detail-about {
  .about {
    background-color: white;
    border: 1px solid #E0E2E4;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .kapo-tags-name {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  .kapo-detail__tags {
    width: 100%;

    .kapo-tags-name {
      width: 100px;
      display: inline-block;
    }

    .kapo-tag-link {
      line-height: 30px;
      display: inline;
    }
  }
}

.kapo-detail-title {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-top: 50px;
}

.kapo-detail-related-events {
  padding-bottom: 60px;
}