
.mark-icon {
  background: url("../img/icon-mark.svg");
}
.calendar-icon {
  background: url("../img/icon-calendar.svg");
}
.clock-icon {
  background: url("../img/icon-clock.svg");
}
.add-icon {
  background: url("../img/icon-add.svg");
}
.person-icon {
  background: url("../img/icon-person.svg");
}
.heart-icon {
  background: url("../img/icon-heart.svg");
}

.search-icon {
  background: url("../img/icon-search.svg");
}

.instagram-icon {
  background: url("../img/icon-instagram.svg");
}

.facebook-icon {
  background: url("../img/icon-facebook.svg");
}

.icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: bottom;
}

.icon-20 {
  width:20px;
  height: 20px  ;
}

.icon-40 {
  width: 40px;
  height: 40px;
}