@import "colors";

.home-page__search {
  background-image: url("../img/KAPO-RELAXOS-HP.png");
  padding: 0;
  background-attachment: fixed;
  background-position-x: center;
  background-size: cover;

  @media (min-width: 992px) {
    height: 450px;

    section {
      align-self: center;
    }
  }
}

@media (min-width: 992px) {

  .px-lg-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .px-lg-60 {
    padding-left: 60px;
    padding-right: 60px;
  }
}


.home-page__search-title {
  padding-top: 36px;
  padding-bottom: 25px;
  color: white;
  font-size: 22px;

  h1 {
    color: #fff;
    line-height: 25px;
    margin-bottom: 0;
    font-weight: 700;
    font-family: Arial, serif;
  }
}


.home-page__search-items {
  background: $yellow;
  border-radius: 10px;
  padding-bottom: 20px;
  margin-bottom: 50px;
  padding-right: 30px;
  padding-left: 30px;

  .col-input {
    padding-right: 10px;
    padding-left: 10px;
  }

  .col-button {
    padding-right: 10px;
    padding-left: 10px;
  }

  @media(min-width: 768px) {
    .col-input {
      width: 30%;
    }

    .col-button {
      width: 10%;
    }
  }
}

.home-page__search-item {

  margin-top: 20px;
  border-radius: 6px;
  padding: 7px 13px;
  background: #FFFFFF;
  height: 55px;

  .select2-selection__placeholder {
    color: black !important;
    font-family: Arial, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }

  .select2-container .select2-selection--single {
    background-color: transparent;
    border: none;
    border-radius: 0 !important;
    padding: 0;


    .select2-selection__rendered {
      padding: 0;
      color: #313131;
    }

    .select2-selection__arrow {
      margin-top: -15px;
      margin-right: 8px;

      b {
        background-image: url("../img/select-arrow.svg");
        background-color: transparent;
        background-size: contain;
        border: none !important;
        height: 20px !important;
        width: 20px !important;
        margin: auto !important;
        top: auto !important;
        left: auto !important;
      }
    }
  }
}

.home-page__search-item-input {

  display: block;
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: black;

  padding: 0;
  font-family: Arial, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;

  &:focus {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $white-50;
    border-radius: 0;
    color: #727272;
    box-shadow: none;
  }

}

.home-page__search-item-title {
  color: black;
  font-family: Arial, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
}

.home-page__search-item-shortcuts {
  margin-top: 5px;
  position: relative;
  z-index: 10;
}

.home-page__search-item-shortcut {
  color: white;
  font-family: Arial, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  cursor: pointer;
  padding-right: 5px;

  &:hover {
    color: $blue;
  }
}
.home-page__search-item-shortcut:not(:last-child) {
  border-right: 1px solid white;

}

.home-page__search_actions {
  margin: 50px 0;

  .btn {
    font-size: 20px;
  }
}

.home-page__recommended {
  background-color: $light-gray;
  padding-top: 40px;
  padding-bottom: 36px;

  h2 {
    font-family: Arial, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 0;
  }
}

.button-show-all {
  background-color: #F3F3F3;
  height: 36px;
  color: $blue-3;
  border-color: $blue-3;
  font-family: Arial, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.home-page__recommended-total {

  font-family: Arial, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: end;
}

.home-page__recommended-categories {
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 991px) {
    justify-content: center;
  }
}

.home-page__recommended-category {
  margin-left: 20px;
  color: $blue;
  cursor: pointer;

  span {
    font-weight: 500;
  }
}

.events-slide-show {
  position: relative;

  .slide-show-swipe {
    position: absolute;
    top: 50%;
    color: $black-25;
    font-size: 26px;
    cursor: pointer;
    transform: translateY(-50px);

    &.swipe-disabled {
      color: $black-25;
    }

    &:hover {
      color: $black-60;
    }
  }

  .slide-show-swipe--left {
    left: 15px;
    z-index: 1000;
  }

  .slide-show-swipe--right {
    right: 15px;
    z-index: 1000;
  }

  .slide-show-events {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: hidden;
    padding: 20px 0;
  }

  .slick-list {
    padding-top: 15px;
    padding-bottom: 15px;

  }

  .slick-dots {
    padding: 0;
    margin-bottom: 20px;
    list-style-type: none;
    display: flex;
    justify-content: center;

    @media (min-width: 768px) {
      margin-left: -110px;
      margin-right: 110px;
    }
  }

  .slick-dots .slick-active span {
    background-color: $blue-3;
  }

  .slick-slide {
    width: 280px;
  }


  .slide-show-dots {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .slide-show-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin-right: 5px;
    background-color: $black-25;

    &.active {
      background-color: $black-60;
    }
  }

}

.home-page__recommended-event {
  background-color: #fff;
  border-radius: 5px;
  min-width: 250px;
  height: 380px;
  border: 1px solid #adb5bd;
  overflow: hidden;
  margin-right: 20px;

  &:hover {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.45);
    transform: scale(1.01)
  }

  .fa-heart {

    color: $blue-3;
  }

  .fa-circle {

    background: white;
  }

  .event-image {
    object-fit: cover;
    width: 100%;
    height: 170px;
    position: relative;

    button {
      position: absolute;
      right: 10px;
      top: 10px;
      background: transparent;
      border: none;
      padding: 0;
      min-width: 30px;

    }
  }

  .event-info {
    padding: 17px 10px 10px;
  }

  .event-categories {
    color: $gray-1;
    font-weight: 900;
    font-size: 13px;
    height: 95px;
    overflow: hidden;

    span {
      margin-right: 10px;
    }
  }

  .event-name {
    margin-top: 10px;
    line-height: 19px;
    color: $blue-3;
    font-size: 16px;
    font-weight: 700;
  }

  .event-description {
    margin-top: 15px;
    font-family: Arial, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: block; /* or inline-block */
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 36px;
    color: black;

  }


  .event-single-info {
    margin-top: 12px;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 14px;

    i {
      color: black;
    }
  }
}

.home-page__recommended-event-placeholder {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 60px;
  max-width: 260px;

  .event-image {
    background-color: $yellow;
  }
}

.homepage-carousel {

}

.homepage-carousel-placeholder {
  overflow-x: hidden;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .homepage-carousel {
    margin-right: -220px;
  }
}

.card-fixed-width {
  width: 270px;
}

@media (max-width: 539px) {
  .home-page__filter-item {
    flex-direction: column;
  }
}

@media (max-width: 991px) {

  .home-page__filter-items {
    flex-direction: column;
  }

  .home-page__filter-items-half {
    width: 100%;
  }

  .home-page__filter-item {
  }
}

@media (min-width: 992px) {
  .home-page__filter-items-half {
    width: 50%;
  }
}

.home-page__filter-title {
  margin-top: 34px;
  margin-bottom: 15px;
  font-family: Arial, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
}

.category-image {
  border-radius: 5px;
}

.home-page__filter-items {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

.home-page__filter-item {
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
  font-family: Arial, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;

  a {
    color: black;

    text-decoration: underline;
  }

  .home-page__town {

    text-decoration: none;
    color: $blue-3;
    font-weight: normal;
    font-size: 14px;
  }
}


.audience {
  margin-bottom: 32px;
}

.audience-wrapper {
  margin-bottom: 10px;
}

.parent-category {
  margin-bottom: 20px;
}

.categories-wrapper {

  span {
    margin-bottom: 15px;

  }

  line-height: 24px;

  .child-category {
    white-space: nowrap;
    font-family: Arial, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    border-right: 1px solid #E5E5E5;
    padding-right: 5px;
  }
  .child-category:last-child {
    border: none;
  }
 }

.home-page__audience-subcategories {
  font-size: 18px;
  font-weight: 400;
}

.home-page__audience-count {
  font-size: 18px;
  font-weight: 400;
  padding-right: 10px;
}

.home-page__town-subcategories {
  font-size: 16px;
  font-weight: 400;
}

.home-page__town-count {
  font-size: 16px;
  font-weight: 400;
  padding-right: 10px;
}

.home-page__category {
  line-height: normal;
  font-size: 20px;
  font-weight: 500;
  white-space: nowrap;
  color: $blue;
}

.home-page__category-count {
  font-size: 16px;
  font-weight: 400;
  padding-right: 10px;
}

.home-page__missing-event {
  margin-top: 60px;
  margin-bottom: 100px;
  background-image: url("../img/KAPO-HP.png");
  background-size: cover;
  resize: both;
}

.home-page__missing-event-title {
  padding-top: 75px;
  color: white;

  h1 {
    color: #fff;
    font-size: 50px;
    font-weight: 700;
  }
}

.home-page__missing-event_actions {
  margin-top: 10px;
  margin-bottom: 100px;

  .btn {
    font-size: 20px;
  }
}

.search-button {
  margin-top: 20px;
  height: 36px;
  border-radius: 5px;
  font-family: Arial, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.4px;
  color: #FFFFFF;

  @media (min-width: 768px) {
    height: 50px;
  }
}

.search-button:hover {
  color: #FFFFFF;
}

.top-search .select2-selection__placeholder {
  font-size: 0.8em;
}

.footer-fixed-bottom {
  position: fixed;
  height: fit-content;
  bottom: 10px;
  width: 100%;
  z-index: 1051;

  .alert {
    margin-bottom: 5px;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

iframe {
  display: block;
  width: 100%;
  height: 100vh;
  border: 0;
}

@media (min-width: 768px) {

  .home-page__recommended {
    padding-left: 200px;
    padding-right: 200px;
  }
}

.home-page__register {
  background: linear-gradient(0deg, $yellow, $yellow), #0353A7;
  height: 200px;
  display: flex;
  flex-wrap: wrap;

  .elipsis {
    margin-left: auto;
    margin-right: auto;
    margin-top: -25px;
    height: 100px;
    width: 120px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    background-color: $yellow;
    left: 0;
    right: 0;

    i {
      margin-top: 10px;
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(292deg) brightness(101%) contrast(102%);
      background-size: contain;
    }
  }

  h2 {
    margin: auto;
    font-family: Arial, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    height: 20px;

    color: white;
    text-align: center;
    margin-top: 50px;
    position: relative;
    margin-bottom: 10px;

    @media (min-width: 768px) {
      font-size: 34px;
      line-height: 34px;
      height: 34px;
    }
  }

  p {
    width: 100%;
    margin-bottom: 0;
    font-family: Arial, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: white;
    text-align: center;
    letter-spacing: -0.01em;
  }

  a {
    margin: auto;
    margin-top: 15px;
    text-align: center;

    button {
      color: white;
      miin-width: 150px;
      height: 36px;
      font-family: Arial, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 40px;
    }
  }
}

footer {
  background-color: #f6f6f6;

  section {
    .row {
      padding-top: 75px;
    }

    padding-bottom: 80px;
  }

  a {
    color: black;
  }

  ul {
    list-style-type: none;

    li {
      i {
        margin-right: 15px;
      }
    }
  }

  .footer-menu-arrow {
    background-image: url('../img/footer-menu-arrow.svg');
    width: 20px;
    height: 20px;
    margin-left: 3px;
    margin-bottom: -3px;
    display: inline-block;
    background-repeat: no-repeat;
  }

  .footer-menu-arrow-flipped {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }


}

.home-page_map {

  @media (min-width: 768px) {

    display: block;
  }
  display: none;
  position: relative;
  margin-top: 60px;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;

  img {
    width: 100%;
  }

  .map-city-wrapper {
    margin-left: -10%;
    margin-right: -10%;
    margin: auto;
    position: relative;
    width: 100%;
    max-width: 1300px;

    #map-cities {
      top: 0;
      width: 100%;
      position: absolute;

      .map-city {
        font-weight: bold;
        text-decoration: underline;
        font-size: 0.95vw;
        line-height: 0.95vw;
        position: absolute;
        -webkit-transition: font-size 0.1s;
        -moz-transition: font-size 0.1s;
        -o-transition: font-size 0.1s;
        transition: font-size 0.1s;
      }

      .map-city:hover {

        padding-left: 2px;
        padding-right: 2px;
        font-size: 1vw;
        line-height: 1vw;
      }
    }
  }
}

.home-page_map:before {

  @media (min-width: 768px) {

    display: block;
  }
  display: none;

  .map-city-wrapper {
    width: 100%;
    display: inline-block;
    position: relative;
  }

  z-index: -2;
  content: ' ';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-image: url("../img/map-background.jpg");
  resize: both;
  background-attachment: fixed;
  background-position-x: center;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-size: cover;

}


.home-page__search-item .select2-container .select2-selection--single
{
  background-image: none;
  .select2-selection__rendered {
    line-height: 50px;
  }
}
.home-page__search-item {

  .datepicker {
    line-height: 50px;
  }
}