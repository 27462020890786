@import "colors";

.btn-fix {
  position: fixed;
  bottom: 12px;
  width: 130px;

}
@media(max-width: 767px) {
  .btn-fix {
    position: fixed;
    bottom: 12px;
    width: inherit;
  }
}


#wrap {
  width: 100%;
  margin: 0 auto;
}

#left_col {
  float: left;
  width: 300px;
}

#right_col {
  float: right;
  width: 300px;
}

.kapo-search {
  background-color: $light-gray;
  padding-top: 40px;
}

.search-container {
  background-color: $light-gray;
}

.search__filter-header {
  padding-top: 40px;
  padding-bottom: 20px;
  background-color: $light-gray;
}

.search__filter-header-actions {
  text-align: right;

  .btn {
    font-size: 13px;
  }

  i {
    color: #fff;
  }

  @media (max-width: 767px) {
    text-align: left;
    margin-top: 10px;
  }
}

.search__filter-header-type {
  font-size: 23px;
  font-weight: 700;
  color: #000;
  padding-right: 15px;
}

.search__filter-header-audience, .search__filter-header {
  font-size: 23px;
  font-weight: 500;
  color: #000;
  padding-left: 15px;
  padding-right: 15px;
}

.search__filter-header-audience {
  border-left: 1px solid $light-gray-2;
}

.search__filter-header-town {
  font-size: 23px;
  font-weight: 400;
  color: #000;
  padding-left: 15px;
  padding-right: 15px;
  border-left: 1px solid $light-gray-2;
}


.filter-item {
  position: relative;
  background-color: $yellow;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 20px;

  .form-group {
    display: flex;
    flex-direction: column;
  }

  &.item-collapsed {
    overflow: hidden;
    height: 45px;
    padding-top: 10px;
  }
  .select2-container--bootstrap-5 .select2-selection--single {
    background-image: none;
    padding: 0.375rem 0.75rem;
  }
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0;
  }
}

.filter-item__chevron {
  color: white;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  transform: translate(-80%, 40%);
}

.filter-item--overview {
  background-color: $blue;
  color: #fff;
}
.filter-item:not(.item-collapsed) .filter-title {
  display: none;
}
.filter-title {
  font-size: 18px;
  font-weight: 700;
  color: white;
}
.filter-item__title {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  color: white;

  &.l-mt-30 {
    margin-top: 30px;
  }
}

.filter-item__value {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 5px;

  i {
    margin-right: 5px;
  }
}

.filter-item__shortcut {
  color: white;
  padding: 0 5px;
  border-right: 1px solid white;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    color: $blue;
  }
}

.filter-item__shortcut--last {
  border: none
}

.filter-item__actions-bar {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 10px;
}

.filter-item__inputs-group {
  padding-left: 20px;

  .filter-item__input {
    font-size: 13px;
  }
}

.filter-item__input {
  display: flex;
  align-items: baseline;
  font-size: 15px;
  margin-top: 5px;
  color: white;

  input {

    cursor: pointer;
    margin-right: 5px;
  }

  label {
    cursor: pointer;
  }

  i {
    cursor: pointer;
  }
}

.filter__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  button {
    margin-bottom: 20px;
    font-size: 16px !important;
    font-weight: 400 !important;
    padding: 8px 25px !important;
  }
}

.btn-save-filter {
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 8px 17px !important;
  background-color: $blue-2 !important;
  color: #fff !important;
}

.btn-cancel-filter {
  font-size: 12px !important;
  font-weight: 400 !important;
  background-color: transparent !important;
  text-decoration: underline !important;
  color: #fff !important;
}

.search-content__order {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: baseline;

  span {
    color: #000;
    font-size: 15px;
    font-weight: 700;
  }

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.search-content__order-select {
  display: flex;
  align-items: baseline;
  text-align: right;
}

.search-content__group {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.search-content__group-title {
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 25px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .search-event {
    flex-direction: column;
  }

  .search-event__date-town {
    flex-direction: column;
  }

  .search-content__group {
    align-items: center;
  }

  .search-event__tags {
    //justify-content: center;
  }
}

.search-event:hover {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.search-event {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: 556px) {
    height: 300px;
  }
}

.search-event__image {

  float: left;
  width: 33%;
  height: 100%;
  position: relative;

  @media (max-width: 556px) {
    width: 100%;
    height: 200px;
    float: initial;
  }

  button {
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent;
    border: none;
    padding: 0;
    min-width: 30px;

    &:hover {
      transform: scale(1.1);
    }

    &:focus {
      outline: none;
    }
  }


  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }

}

.search-event__info {
  width: 66%;
  float: left;
  padding: 25px;
  overflow: hidden;
  flex-direction: column;

  @media (max-width: 556px) {
    width: 100%;
    float: initial;
  }
}

.search-event__name {
  font-size: 19px;
  font-weight: 700;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.search-event__place {
  font-size: 19px;
  font-weight: 700;
  color: #000;
}

.search-event__date-town {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 700;

  .date-town-item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 10px;
    color: #000;

    @media (max-width: 556px) {
      max-width: 260px;
    }
  }

  i {
    color: $black-50;
    margin-right: 5px;
  }
}

.search-event__description {
  color: $black-80;
  font-size: 14px;
  max-height: 110px;
  overflow: hidden;
}

.search-event__tags {
  margin-top: 15px;
  display: flex;
  overflow: hidden;
}

.search-event__tag {
  margin-right: 5px;
  padding: 5px 10px;
  font-size: 10px;
  color: $blue;
  font-weight: 500;
  border: 1px solid $blue;
  border-radius: 25px;
  white-space: nowrap;
}

.select2-results {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #F3F3F3;
    width: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #CFCFCF;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .select2-results__option--highlighted {
    background-color: #489ffa !important;
  }
}

.options-container {
  padding-top: 20px;
  padding-bottom: 20px;

  #filter-dropdown-button {
    background-color: white;
  }

  #save-filter-button {
    color: white;
    font-weight: bold;
  }

  .sort-container {
    span {
      font-weight: 700;
      font-size: 20px;
    }
  }


  @media (min-width: 556px) {

    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }

  .saved-search-row:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  .saved-search-row {
    position: relative;
    padding: 8px 20px 8px 5px;

  }

  .saved-search-row:hover {
    background-color: $light-gray;
  }
}
.remove-icon {
  border-radius: 50%;
  background-color: rgb(200, 20, 20);
  color: white;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
}

.remove-icon:hover {
  cursor: pointer;
  background-color: rgb(250, 20, 20);
}
.remove-filter {
  position: absolute;
  right: 5px;
  top: 5px;
}
.applyBtn {
  color: white;
}

.applyBtn:hover {
  color: white;
}

#daterange-filter {
  background-color: white;
  border: none;

  padding: 0.375rem 0.75rem;
}

.save-filter-button {
  height: 36px;
  border-radius: 5px;
  font-family: Arial, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  text-align: center;
  letter-spacing: 0.4px;
  color: white;
}

.search__left-panel {
  margin-bottom: 20px;
}

.select-search-box {
  position: absolute;
  height: 32px;
  padding: 5px;
  border-radius: 8px;
  background: white;
  color: $blue;
  border: 1px solid $blue;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.4);

  .select-search-text {
    font-weight: bold;
  }

  .select-search-text:before {
    content: "'";
  }

  .select-search-text:after {
    content: "'";
  }

  .select-search-link {
    text-decoration: underline;
    font-weight: bold;
  }

  .select-search-close {
    margin-left: 10px;
    color: red;
    font-size: 10px;
    line-height: 10px;
    vertical-align: text-top;
    font-weight: bold;
  }

  .select-search-close:hover {
    cursor: pointer;
  }
}
.select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered .select2-selection__placeholder {
  color: black;
}
.filter-dropdown {
  background-color: white;
  font-weight: bolder;
  text-decoration: underline;
  width: 40vw;
}
@media (max-width: 767px) {
  .filter-dropdown {
    width: 100vw;
  }
}