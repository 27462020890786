@import "colors";


.register-form__actions {
  margin-top: 10px;
  color: $blue-2;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: white;
    text-decoration: underline;
  }
}

.register-form__btn-register {
  margin-top: 20px;
  height: 36px;
  border-radius: 5px;
  font-family: Arial, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.4px;
  color: #FFFFFF;

  @media (min-width: 768px) {
    height: 50px;
  }
}

.register-title {
  line-height: 25px;
  margin-bottom: 20px;
  font-weight: 700;
  font-family: Arial, serif;
  color: #FFF;
}



.register-wrapper {

  padding-left: 0;
  @media (min-width: 992px) {
    padding-left: 100px;
  }
  min-height: 80vh;
  padding-bottom: 50px;
  height: fit-content;
  background: url("../img/login-background.jpg") fixed;
  background-position-y: center;
  background-position-x: center;
}

.register-container {

  margin-top: 50px;
  background-color: $yellow;
  border-radius: 5px;
  padding: 20px 10px 10px;

}

.kapo-register-form {

  .fa-google {
    color: red;
  }

  .fa-facebook-square {
    color: blue;
  }

  input {
    margin-top: 10px;
    border: none;
  }

  input::placeholder {
    color: black;
  }
}
