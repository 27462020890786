#modal_skeleton {
  .modal-footer {

    button, input[type=submit] {
      color: white;
      min-width: 150px;
      min-height: 36px;
      font-family: Arial, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
    }
  }
}