@import "colors";

.kapo-form-category {
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.15);
  padding: 20px;
  border-radius: 15px;
  background-color: #ffffff;
}

.kapo-form-group {
  .control-label {
    font-weight: 500;
    color: #000000;
  }
}

.kapo-form-select-group {
  border-radius: 8px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.15);
  background-color: $gray-2;
  padding: 15px 45px 15px 15px;

  .control-label {
    margin-top: 10px;
    font-weight: 500;
    color: #000000;
  }

  .kapo-checkbox-label {
    margin: 0;
  }
}

.kapo-form-image-group {
  border-radius: 8px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.15);
  //background-color: $gray-2;
  padding: 15px;

  .control-label {
    font-weight: 500;
    color: #000000;
  }

  &.kapo-form-image-group--gallery {
    .form-group:first-of-type {
      display: flex;
      align-items: baseline;

      ul {
        .form-group {
          display: block;
        }
      }
    }
  }
}


.form_group {
  margin-top: 20px;
  background-color: white;
}

.image_form_preview {
  display: block;
  max-width: 230px;
  max-height: 95px;
  width: auto;
  height: auto;
  border-radius: 5px;
  margin-bottom: 20px;
}

.listless {
  list-style: none;
}

.listless-no-padding {
  padding-left: 0;
}
o
.file_input_wrapper {
  input[type=file] {
    display: block !important;
    width: 100%;
    height: 36px;
    margin-bottom: 5px;
    cursor: pointer
  }
}


.contact_data_list_item {
  padding-top: 10px;
  align-items: baseline;
}

.add-another-collection-widget {
  margin-bottom: 10px;
}

.form-group, .search-content__order-select {
  .form-control {
    background-color: white;
    padding: 0.5em 0.375em;
    height: auto;
  }
}

.form-input-error {
  border-color: #d93e36 !important;
  padding-right: calc(1.5em + 1.5rem) !important;
}
.form-control {
  background-color: white;
}